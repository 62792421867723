import React from 'react'
import Gallery from './Gallery'
import Ui from './Ui'
import graph from './graph.svg'
import graphVertical from './graph_vertical.svg'
import poster from './social.jpg'
import Layout, { titleCss, CaseStudySpacer } from '~shared/CaseStudyLayout'
import CaseStudyVideo, { Wrapper as VideoWrapper } from '~shared/CaseStudyVideo'
import CaseStudyTitle from '~shared/CaseStudyTitle'
import CaseStudyTextWrapper from '~shared/CaseStudyTextWrapper'
import CaseStudyQuote from '~shared/CaseStudyQuote'
import CaseStudyLinks from '~shared/CaseStudyLinks'
import CaseStudyReadMore from '~shared/CaseStudyReadMore'
import Graph from '~shared/Graph'
import { Link } from 'streamr-ui'

const PaveMotors = () => (
    <Layout>
        <CaseStudyTitle name="Pave" summary="Next generation Web3 mobility system" css={titleCss} />
        <VideoWrapper>
            <CaseStudyVideo study="pave" poster={poster} />
        </VideoWrapper>
        <CaseStudySpacer as={CaseStudyTextWrapper}>
            <p $appear>
                On the surface, Pave looks like any other e-scooter and mobility startup we&apos;ve
                seen before, but if you look a little deeper, they are taking a radically different
                approach. Headquartered in Brooklyn, and launched in mid-2021, Pave is leveraging
                the strengths of Web 3.0 to offer riders a suite of features your&nbsp;average ride
                service has never seen before.
            </p>
            <p $appear>
                Pave riders can rent their bikes to each other, monetise their mobility data,
                connect with the infrastructure around them and even earn yield on their
                ride&#x2011;sharing profits.
            </p>
            <p $appear>
                This is made possible by building on the Streamr Network and leveraging its
                decentralized architecture for data transport. Each bike runs an instance of
                a&nbsp;light Streamr node which unlocks the Pave bikes&apos; V2X,
                vehicle-to-everything, functionality. Meaning that the bikes are free to directly
                interact with network service providers (V2P), the environment and infrastructure
                around them (V2I)&nbsp;or even with other bikes (V2V).
            </p>
        </CaseStudySpacer>
        <Ui />
        <CaseStudySpacer as={CaseStudyTextWrapper}>
            <p $appear>
                Therefore, anyone with a Pave bike can rent out their vehicle to anyone who&apos;s
                got the Pave app installed on their phone. Bike ownership title and owner access
                control security is enforced by an NFT (ERC721). Possession of the token
                corresponding to the physical bike in the owners&apos; Pave dApp grants access to
                the bike via Bluetooth - without the need for an internet connection.
            </p>
        </CaseStudySpacer>
        <CaseStudyQuote author="Nico Nagel, Co-founder, Pave Motors" $appear>
            In order to truly change mobility as we know it, we believe in the potential of
            decentralization and disintermediation that blockchain-based applications enable. By
            working with Streamr we can give our riders full control over their data as well as
            offer another monetization opportunity.
        </CaseStudyQuote>
        <CaseStudySpacer as={CaseStudyTextWrapper}>
            <p $appear>
                In addition, all user metrics data is relayed through Streamr&apos;s decentralized
                peer-to-peer network directly to the Pave dapp, bypassing centralised services
                entirely, as well as helping to secure and extend the Streamr Network. Pave riders
                are in full control of their driving data which they can monetize on their own terms
                via Streamr&apos;s Data Union framework. This allows for crowdsourced mobility
                datasets to be sold on The Hub with revenue shared between all contributing riders.
            </p>
        </CaseStudySpacer>
        <Graph
            src={graph}
            verticalSrc={graphVertical}
            caption="Overview of how data flows in Pave's tech stack"
        />
        <CaseStudySpacer as={CaseStudyTextWrapper}>
            <p $appear>
                The Pave wallet supports crypto and fiat payments which are exchanged between bike
                owners, fleet operators, renters or service providers as a means of exchange for
                rental, services, discounts or other network rewards or incentives. With full
                tokenomics coming to the Streamr Network in the Tatum milestone, Pave riders will
                even have the opportunity to become yield farmers, earning revenue for their
                contribution to the&nbsp;network.
            </p>
        </CaseStudySpacer>
        <Gallery />
        <CaseStudySpacer as={CaseStudyTextWrapper}>
            <p $appear>
                The future of transportation does not have to be in the hands of a&nbsp;few
                centralised service providers. Ride-sharing can be done in a&nbsp;decentralized
                fashion, creating sharing economies in which everyone becomes an equitable
                participant. Pave is offering a&nbsp;glimpse of the myriad of opportunities through
                which the decentralized web stack will impact how we will move around&nbsp;tomorrow.
            </p>
        </CaseStudySpacer>
        <CaseStudySpacer
            as={CaseStudyReadMore}
            techStack={
                <CaseStudyLinks title="Tech stack">
                    <Link href="https://streamr.network/network-explorer/">Streamr Network</Link>
                    <Link href="https://streamr.network/discover/data-unions/">Data Unions</Link>
                    <Link href="https://www.pavemotors.com/platform" blank>
                        NFT access control
                    </Link>
                </CaseStudyLinks>
            }
            readMore={
                <CaseStudyLinks title="Learn more">
                    <Link href="https://www.pavemotors.com" blank>
                        Pave Motors
                    </Link>
                    <Link
                        href="https://newlab.com/articles/nicolaus-nagel-of-pave-motors-answers-four-questions-for-founders/"
                        blank
                    >
                        Pave on Newlab
                    </Link>
                </CaseStudyLinks>
            }
        />
    </Layout>
)

export default PaveMotors
