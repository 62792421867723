import React from 'react'
import styled from 'styled-components'
import { MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import phone from './assets/phone.png'
import Figure from '~shared/Figure'
import Container from '~shared/Container'

const Wrapper = styled.div`
    background-color: #efefef;
`

const Image = styled.div`
    display: flex;
    overflow: hidden;
    padding: 0;
    padding-top: 64px;
    margin: 0 auto;
    max-width: 255px;
    position: relative;

    img {
        width: 100%;
    }

    @media ${MqTablet} {
        max-width: 306px;
        padding-top: 80px;
    }

    @media ${MqDesktop} {
        max-width: 488px;
        padding-top: 100px;
    }
`

const Ui = (props) => (
    <Figure {...props}>
        <Wrapper>
            <Container>
                <Image>
                    <img src={phone} alt="" $appear />
                </Image>
            </Container>
        </Wrapper>
        <Figure.Caption $appear>
            <Container>
                The Pave dApp controls access with NFTs and gives riders a range of data
            </Container>
        </Figure.Caption>
    </Figure>
)

export default Ui
