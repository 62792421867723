import React, { useState } from 'react'
import styled from 'styled-components'
import { MqDesktop } from 'streamr-ui/css/consts'
import slide0 from './assets/slide0.jpg'
import slide1 from './assets/slide1.jpg'
import slide2 from './assets/slide2.jpg'
import Container from '~shared/Container'
import G, { slideIndex } from '~shared/Gallery'
import Figure from '~shared/Figure'
import Spacer from '~shared/Spacer'

const UnstyledGallery = (props) => {
    const [slide, setSlide] = useState(1)

    const index = slideIndex(slide, 3)

    return (
        <Spacer {...props} head="48px,88px,144px" tail="56px,72px,56px" pad>
            <G.Constraint>
                <Container>
                    <Figure>
                        <G.Viewport $appear>
                            <G slide={index} onChange={setSlide}>
                                <G.Item src={slide0} alt="" />
                                <G.Item src={slide1} alt="" />
                                <G.Item src={slide2} alt="" />
                            </G>
                        </G.Viewport>
                        <Spacer as={Figure.Caption} head="32px,64px,44px" pad $appear>
                            Pave&apos;s HQ in the Brooklyn Navy Yard
                        </Spacer>
                    </Figure>
                </Container>
            </G.Constraint>
        </Spacer>
    )
}

const Gallery = styled(UnstyledGallery)`
    background-color: #f5f5f5;

    ${Figure.Caption} {
        margin-top: 0;
    }

    ${G.SlideContainer} {
        padding: 0 12px;

        @media ${MqDesktop} {
            padding: 0 20px;
        }
    }
`

export default Gallery
